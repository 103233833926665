import React, { Component } from 'react';
import InfoGraphic from '../Infographics/InfoGraphic'
import WOW from "wowjs";
import './Features.css'

class Features extends Component {
    constructor(props) {
        super(props);
        this.featuresRef = React.createRef();
    }

    componentDidMount() {
        const wow = new WOW.WOW({ live: false});
        wow.init();
      }
  render() {
    return (
      <div className="App" id="features">
        <section className="features section text-center ">
        <div >
                    <div className="features-inner section-inner">
						<div className="features-header text-center">
							<div className="container">
                                <h2 className="section-title mt-0 header-color  wow fadeInUp"> <span> FEATURES</span> </h2>
                       <div className="features-wrap container">
                            <div className="feature wow fadeInUp" data-wow-offset="50" data-wow-delay="0.1s">
                                <div className="feature-inner  feature-border">
                                    <div className="feature-icon">
                                        <i className="user icon "></i>
                                    </div>
                                    <h4 className="feature-title header-color">Visitor Tracker</h4>
                                    <p className="text-sm feature-content">Create a unique process for visitor check-in so that your front desk staff can engage in more valuable activities.</p>
                                </div>
                            </div>
                            <div className="feature  wow fadeInUp" data-wow-offset="50" data-wow-delay="0.2s">
                                <div className="feature-inner  feature-border">
                                    <div className="feature-icon">
                                    <i className="dashboard icon large "></i>
                                    </div>
                                    <h4 className="feature-title header-color">Web Dashboard</h4>
                                    <p className="text-sm feature-content">A cloud-based easy to use web dashboard allows your team to view live activity, customise your setup and run reports from any browser.</p>
                                </div>
                            </div>
                            <div className="feature wow fadeInUp" data-wow-offset="50" data-wow-delay="0.3s">
                                <div className="feature-inner  feature-border">
                                    <div className="feature-icon">
                                    <i className="wrench icon large "></i>
                                    </div>
                                    <h4 className="feature-title header-color">Customisable Interface</h4>
                                    <p className="text-sm feature-content">Nail that first impression with a highly customizable interface of your iPad or Android tablet</p>
                                </div>
                            </div>
                            <div className="feature  wow fadeInUp" data-wow-offset="50" data-wow-delay="0.1s">
                                <div className="feature-inner  feature-border">
                                    <div className="feature-icon">
                                        <i className="map marker alternate icon large"></i>
                                    </div>
                                    <h4 className="feature-title header-color">Employee check-in</h4>
                                    <p className="text-sm feature-content">Not just the visitors, now your employees can mark their check-in & logouts. </p>
                                </div>
                            </div>
                            <div className="feature wow fadeInUp" data-wow-offset="50" data-wow-delay="0.2s">
                                <div className="feature-inner  feature-border">
                                    <div className="feature-icon">
                                        <i className="id badge icon large "></i>
                                    </div>
                                    <h4 className="feature-title header-color">Pre-register guest</h4>
                                    <p className="text-sm feature-content">Hosts can pre-register guests and send email invites filled with everything visitors need to know about their upcoming meeting to speed up the check-in process.</p>
                                </div>
                            </div>
                            <div className="feature wow fadeInUp" data-wow-offset="50" data-wow-delay="0.3s" >
                                <div className="feature-inner  feature-border">
                                    <div className="feature-icon">
                                        <i className="envelope open outline icon "></i>
                                    </div>
                                    <h4 className="feature-title header-color">Notification Via e-mail</h4>
                                    <p className="text-sm feature-content">We notify your employees via email when they have a visitor.</p>
                                </div>
                            </div>
                        </div>
							</div>
                            
						</div>
            </div>
            </div>
        </section>
            <InfoGraphic />
      </div>
    );
  }
}

export default Features;
