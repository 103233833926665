import React, { Component } from 'react';
import { Grid, List, Icon } from "semantic-ui-react";
import logo from './assets/images/logo.png'
import Content from './components/MainContent/Content';
import Footer from './components/Footer/Footer';
import { Link } from 'react-scroll';
import { DASH_LINK } from './config';
import './App.css'

class App extends Component {
  render() {
    return (

      <div id="appStyle" className="App">
        <div className="heading">
          <div className="container">
            <Grid>
              <Grid.Row only="computer">
                <Grid.Column only="computer" computer={3}>
                  <List bulleted horizontal>
                    <List.Item className="links">
                      <span className="logoPosition">
                        <img className="logo" src={logo} alt="Brand logo" />
                      </span>
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column floated='right' mobile={16} computer={8} tablet={13}>
                  <List bulleted horizontal>
                    <List.Item className="links">
                      <Link 
                        to="home"
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={500}>
                        Home
                        </Link>
                    </List.Item>
                    <List.Item className="links">
                      <Link
                        to="features"
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={500}>
                        Features
                      </Link>
                    </List.Item>
                    <List.Item className="links">
                      <Link
                        to="pricing"
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={500}>
                        Pricing
                      </Link>
                    </List.Item>
                    <List.Item className="links" as='a' href={DASH_LINK}>Login</List.Item>
                    <List.Item className="links" as='a' onClick={() => {
                      this.refs.modal.handleOpen()
                    }}>Signup</List.Item>

                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row only="mobile">
                <Grid.Column floated='right' mobile={16}>
                  <List horizontal>
                    <List.Item className="links">
                      <Link
                        to="home"
                        smooth={true}
                        offset={50}
                        duration={500}>
                        <Icon name="home"></Icon>
                        </Link>
                    </List.Item>
                    <List.Item className="links">
                      <Link
                        to="features"
                        smooth={true}
                        offset={0}
                        duration={500}>
                        <Icon name="settings"></Icon>
                      </Link>
                    </List.Item>
                    <List.Item className="links">
                      <Link
                        to="pricing"
                        smooth={true}
                        offset={50}
                        duration={500}>
                        <Icon name="dollar sign"></Icon>
                      </Link>
                    </List.Item>
                    <List.Item className="links">
                        <a href={DASH_LINK}>
                        <Icon name="sign-in">
                        </Icon>
                        </a>
                    </List.Item>
                    <List.Item className="links" onClick={() => {
                      this.refs.modal.handleOpen()
                    }}>
                        <span>
                        <Icon name="signup" >
                        </Icon>
                        </span>
                        
                    </List.Item>

                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
        <Content ref="modal" />
        <Footer />
      </div>
    );
  }
}

export default App;
