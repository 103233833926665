
const isEmpty = field => {
    if(typeof(field)===undefined || field.length === 0) {
        return true
    } else {
        return false
    }
}

const isValid = (name, field) => {
    let result
    switch(name) {
        case 'name': result = /^[A-Za-z ]+$/.test(field) ? true : false;
                     break;
        case 'email': result = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(field) ? true : false;
                     break;
        case 'phone': result = /[\d]/.test(field) ? true : false;
                     break; 
        case 'password': result= field.length>6 ? true : false;
                     break;
        default :   result = true
                     break;
    }
    return result
}

module.exports = {
    isEmpty,
    isValid
} 