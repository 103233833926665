import React, { Component } from "react";
import {
  Modal,
  Form,
  Checkbox,
  Message,
  Grid,
  Loader,
  Dimmer,
  Icon,
  TransitionablePortal,
} from "semantic-ui-react";
import Features from "../Features/Features";
import Pricing from "../Pricing/Pricing";
import Button from "../../utils/PosedButton";

import Validator from "../../utils/validators";
import Config from "../../config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Content.css";
import { DASH_LINK } from '../../config/index'
class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      companyName: "",
      role: "",
      phone: "",
      email: "",
      address: "",
      password: "",
      confirmPassword: "",
      formError: false,
      loader: false,
      disableForm: false,
      open: false,
      checked: false,
      checkedError: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  validateField = event => {
    if (Validator.isEmpty(event.target.value)) {
      this.setState({
        errorMessage: "Highlighted fields are required!",
        formError: true,
        [event.target.name + "Error"]: true
      });
    } else if (!Validator.isValid(event.target.name, event.target.value)) {
      this.setState({
        errorMessage: `${event.target.name} is not valid`,
        formError: true,
        [event.target.name + "Error"]: true,
        [event.target.name + "Invalid"]: true
      });
    } else {
      this.setState({
        formError: false,
        [event.target.name + "Error"]: false,
        [event.target.name + "Invalid"]: false
      });
    }
  };

  validateConfirmPassword(event) {
    if (Validator.isEmpty(event.target.value)) {
      this.setState({
        fieldEmpty: true,
        errorMessage: "Highlighted fields are required!",
        formError: true,
        [event.target.name + "Error"]: true
      });
    }
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        formError: true,
        [event.target.name + "Invalid"]: true
      });
    } else {
      this.setState({
        formError: false,
        [event.target.name + "Error"]: false,
        [event.target.name + "Invalid"]: false
      });
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    if (!this.state.checked) {
      this.setState({
        formError: true,
        disableForm: true,
        checkedError: true
      });
    }
    const {
      name,
      companyName,
      role,
      phone,
      email,
      address,
      password
    } = this.state;
    const errorCheck =
      name === ""
        ? true
        : companyName === ""
        ? true
        : password === ""
        ? true
        : email === ""
        ? true
        : password === ""
        ? true
        : false;
    console.log(name,
      companyName,
      phone,
      email,
      password)
    this.setState(
      {
        formError: errorCheck,
        disableForm: errorCheck
      },
      state => {

        console.log(this.state)
        console.log(this.state)
        if (!this.state.formError || !this.state.disableForm) {
          this.setState({
            loader: true
          });
          axios
            .post(`${Config.API_URL}/auth/signup`, {
              name,
              companyName,
              role,
              phone,
              email,
              location: address,
              password
            })
            .then(response => {
              this.setState({
                loader: false,
                name: "",
                companyName: "",
                role: "",
                phone: "",
                email: "",
                address: "",
                password: "",
                confirmPassword: "",
                open: false
              });
              toast.success("Signup Success!!!! Please Confirm From Your Mail Account ");
              
            })
            .catch(error => {
              this.setState({
                loader: false
              })
              console.log(error)
              const errMessage = error.response 
              ? error.response.data.error 
              : `Yikes! There's an error!`;
              toast.error(errMessage)
            });
        }
      }
    );
  };

  render() {
    const RenderError = props => {
      if (props.isError) {
        return <Message error compact size="mini" content={props.message} />;
      } else return null;
    };
    return (
      <div id="home">
        <section>
          <div>
            <section className="hero">
              <div className="hero-right-decoration is-revealing" />
              <div className="container">
                <div className="hero-inner">
                  <div className="hero-copy">
                    <h1 className="hero-title mt-0 is-revealing">
                      Impress your visitors with easy and swift check-in system.
                    </h1>
                    <p className="hero-paragraph is-revealing main-para">
                      The visitor management application works seamlessly across platforms in both iPad and Android tablets. You just need to set it up once and collect visitor data forever.
                    </p>
                    <Button
                      className="button button-secondary button-shadow buttonColorCustom"
                      onClick={this.handleOpen}
                    >
                      Signup
                    </Button>
                    <TransitionablePortal
                      open={this.state.open}
                      transition={{ animation: "scale", duration: 150 }}
                    >
                      <Modal
                        open={true}
                        onClose={event => {
                          document.body.classList.remove("modal-fade-in");
                          this.handleClose();
                        }}
                      >
                        <Modal.Header>Signup</Modal.Header>

                        <Modal.Content>
                          <Dimmer active={this.state.loader}>
                            <Loader />
                          </Dimmer>
                          <Modal.Description>
                            <Form
                              error={this.state.formError}
                              size={"mini"}
                              className="attached fluid segment"
                            >
                              <Form.Group>
                                <Form.Input
                                  autoFocus
                                  label="Name"
                                  name="name"
                                  placeholder="Name"
                                  value={this.state.name}
                                  width={8}
                                  error={
                                    this.state.nameError ||
                                    this.state.nameInvalid
                                  }
                                  onChange={this.handleChange}
                                  onBlur={this.validateField}
                                />

                                <Form.Input
                                  label="Company Name"
                                  name="companyName"
                                  placeholder="Company Name"
                                  value={this.state.comapnyName}
                                  width={8}
                                  onChange={this.handleChange}
                                  error={this.state.companyNameError}
                                  onBlur={this.validateField}
                                />
                              </Form.Group>
                              <RenderError
                                isError={this.state.nameInvalid}
                                message="Name can't have numbers or special characters"
                              />
                              <Form.Group>
                                <Form.Input
                                  label="Email"
                                  name="email"
                                  placeholder="Email"
                                  width={8}
                                  value={this.state.email}
                                  onChange={this.handleChange}
                                  error={
                                    this.state.emailError ||
                                    this.state.emailInvalid
                                  }
                                  onBlur={this.validateField}
                                />
                                {/* <RenderError isError={this.state.emailInvalid} message="Not a valid email address"/> */}
                                <Form.Input
                                  label="Phone"
                                  name="phone"
                                  placeholder="Phone"
                                  width={8}
                                  value={this.state.phone}
                                  onChange={this.handleChange}
                                  error={
                                    this.state.phoneError ||
                                    this.state.phoneInvalid
                                  }
                                  onBlur={this.validateField}
                                />
                                {/* <RenderError isError={this.state.phoneInvalid} message="Not a valid phone number"/> */}
                              </Form.Group>
                              <Grid columns={2}>
                                <Grid.Column>
                                  <RenderError
                                    isError={this.state.emailInvalid}
                                    message="Not a valid email address"
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <RenderError
                                    isError={this.state.phoneInvalid}
                                    message="Not a valid phone number"
                                  />
                                </Grid.Column>
                              </Grid>
                              <Form.Group>
                                <Form.Input
                                  label="Password"
                                  name="password"
                                  type="password"
                                  placeholder="Password"
                                  width={8}
                                  value={this.state.password}
                                  onChange={this.handleChange}
                                  error={
                                    this.state.passwordError ||
                                    this.state.passwordInvalid
                                  }
                                  onBlur={this.validateField}
                                />

                                <Form.Input
                                  label="Confirm Password"
                                  name="confirmPassword"
                                  type="password"
                                  placeholder="Password"
                                  width={8}
                                  value={this.state.confirmPassword}
                                  onChange={this.handleChange}
                                  error={this.state.confirmPasswordError}
                                  onBlur={this.validateConfirmPassword}
                                />
                              </Form.Group>
                              <Grid columns={2}>
                                <Grid.Column>
                                  <RenderError
                                    isError={this.state.passwordInvalid}
                                    message="Password needs to be more than 6 characters."
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <RenderError
                                    isError={this.state.confirmPasswordInvalid}
                                    message="Passwords do not match!"
                                  />
                                </Grid.Column>
                              </Grid>
                              {/* <Checkbox
                                label="I agree to the terms and services."
                                className="superCheckbox"
                                onChange={() => {
                                  this.setState({
                                    checked: !this.state.checked
                                  });
                                }}
                              /> */}
                              {/* <br />
                              <RenderError
                                isError={this.state.checkedError}
                                message="Agree first"
                              /> */}
                              <br />
                              {/* <Button
                                className="button button-secondary button-shadow buttonColorCustom"
                                type="submit"
                                pose="visible"
                                onClick={this.handleSubmit}
                                style={{ marginTop: "10px"}}
                              >
                                Signup
                              </Button> */}
                                       <Button
                      className="button button-secondary button-shadow buttonColorCustom"
                      onClick={this.handleSubmit}
                    >
                      Signup
                    </Button>
                            </Form>
                            <Message size={"mini"} attached="bottom">
                              <Icon name="help" />
                              Already a member?&nbsp;
                              <a href="https://dashboard.vizits.me">Login here</a>
                              {/* <a href={DASH_LINK}>Login here</a> */}
                              &nbsp;instead.
                            </Message>
                          </Modal.Description>
                        </Modal.Content>
                      </Modal>
                    </TransitionablePortal>
                  </div>
                  <div className="hero-illustration" />
                </div>
              </div>
            </section>
          </div>
        </section>
        <Features />
        <Pricing />
        <ToastContainer autoClose={3000} />
      </div>
    );
  }
}

export default Content;
