import React, { Component } from 'react';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-scroll';
class Footer extends Component {
  render() {
    return (
      <div className="App">
      <footer className="site-footer">
            <div className="container">
                <div className="site-footer-inner">
                    <div className="brand footer-brand">
                        <Link 
                        to="home"
                        smooth={true}
                        offset={0}
                        duration={250}>
                            <img className="logo" src={logo} alt="Brand logo" />
                        
                        </Link>
                    </div>
                    <ul className="footer-links list-reset">
                    </ul>
                    <ul className="footer-social-links list-reset">
                    </ul>
                    <div className="footer-copyright">© 2019 Daddy's Pocket Group Private Limited</div>
                </div>
            </div>
        </footer>
      </div>
    );
  }
}

export default Footer;