// const API_URL = 'https://api-visits.daddyspocket.com';
// const DASH_LINK = 'http://visits-dashboard.daddyspocket.com'
const API_URL = 'https://api.vizits.me'
//const API_URL = 'http://localhost:3000'
const DASH_LINK = 'https://dashboard.vizits.me//#/auth/login'

module.exports = {
    API_URL,
    DASH_LINK
}
