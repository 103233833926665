import React, { Component } from 'react';
import infographic from "../../assets/images/infographic.png"
import WOW from "wowjs";
import './infographics.css'

class InfoGraphic extends Component {
    componentDidMount() {
        const wow = new WOW.WOW({ live: false });
        wow.init();
    }
    render() {
        return (
            <div className="App botton-margin">
                <div className="container text-center">
                    <h2 className="header-color wow fadeInUp"> HOW IT WORK'S </h2>
                </div>
                <div className="wow fadeInUp" data-wow-offset="50" data-wow-delay="0.1s">
                    <div className="container wow fadeInUp">
                        <img className="infographics" src={infographic} alt="vm-infographic" />
                    </div>
                </div>
            </div>
        );
    }
}

export default InfoGraphic;
