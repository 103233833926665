import React, { Component } from 'react';
import Config from '../../config/';
import { Grid } from "semantic-ui-react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import WOW from "wowjs";
import Button from '../../utils/PosedButton';
import './Pricing.css'

class Pricing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			enqEmail: "",
			enqName: "",
			enqPhone: "",
			buttonText: "Submit"
		}
	}
 	componentDidMount() {
		  const wow = new WOW.WOW({ live: false});
		wow.init();
	}

	handleEnquiry = () => {
		const warning = {
			className: 'custom-toast',
			progressClassName: 'warn-progress'
		};
		const re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm
		const _self = this;
		this.setState({
			buttonText: "Submitting..."
		})
		const {enqEmail, enqPhone, enqName} = this.state;
		console.log(this.state)
		if (enqEmail && enqName && enqPhone && re.test(this.state.enqEmail)) {
			axios
				.post(`${Config.API_URL}/auth/enquiry`, {
					email: enqEmail,
					name: enqName,
					phone: enqPhone
				})
				.then(response => {
					toast.success("Enquiry submitted!");
					_self.setState({
						enqEmail: "",
						enqPhone: "",
						enqName: "",
						buttonText: "Submit"
					})
				})
				.catch(err => {
					toast.error("Something went wrong!");
				})
		} else if (!re.test(this.state.enqEmail)) {
			toast(`Valid email is required!`, warning)
			this.setState({
				buttonText: 'Submit'
			})
		} 
		else  if(!this.state.enqEmail){
			toast("Email is required!", warning);
			this.setState({
				buttonText: "Submit"
			})
		} else if (!this.state.enqName) {
			toast("Name is required!", warning);
			this.setState({
				buttonText: "Submit"
			})
		} else if (!this.state.enqPhone) {
			toast("Phone is required!",warning);
			this.setState({
				buttonText: "Submit"
			})
		}
	}
	render() {
		return (
			<div>
				<section id="pricing" className="pricing section pricing-background">
					<div className="container">
						<div className="pricing-inner section-inner">
							<h2  className="section-title mt-0 text-center pricing-header-color">PRICING</h2>
							<div><div>
								<div className="pricing-tables-wrap">
									<div className="pricing-table is-revealing wow fadeInUp" data-wow-delay="0.1s">
										<div className="pricing-table-inner">
											<div className="pricing-table-main">
												<div className="pricing-table-header mb-24 pb-24">
													<div className="pricing-table-title h4 mt-0 mb-16 pricing-plan-name text-center">BASIC</div>
													<div className="pricing-table-price text-center"><span className="pricing-table-price-currency pricing-plan-name">₹</span><span className="pricing-table-price-amount h1 pricing-plan-name">500</span><span style={{color:"#b9c3cf"}}>/month</span></div>
												</div>
												<ul className="pricing-table-features list-reset text-xs pricing-text-content">
													<li>
														<span>One location</span>
													</li>
													<li>
														<span>Link a single iPad/Tablet</span>
													</li>
													<li>
														<span>Unlimited visitors</span>
													</li>
													<li>
														<span>25 employees (hosts)</span>
													</li>
													<li>
														<span>24-hour customer care Mon-Fri</span>
													</li>
													<li>
														<span>No setup costs</span>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div className="pricing-table is-revealing wow fadeInUp" data-wow-delay="0.3s">
										<div className="pricing-table-inner">
											<div className="pricing-table-main">
												<div className="pricing-table-header mb-24 pb-24">
													<div className="pricing-table-title h4 mt-0 mb-16 pricing-plan-name text-center">PREMIUM</div>
													<div className="pricing-table-price text-center"><span className="pricing-table-price-currency pricing-plan-name">₹</span><span className="pricing-table-price-amount h1 pricing-plan-name">1000</span><span style={{color:"#b9c3cf"}}>/month</span></div>
												</div>
												<ul className="pricing-table-features list-reset text-xs pricing-text-content">
													<li>
														<span>Two location</span>
													</li>
													<li>
														<span>2 iPad's/Tablet's</span>
													</li>
													<li>
														<span>Unlimited visitors</span>
													</li>
													<li>
														<span>50 employees (hosts)</span>
													</li>
													<li>
														<span>24-hour customer care Mon-Fri</span>
													</li>
													<li>
														<span>No setup costs</span>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div className="pricing-table is-revealing wow fadeInUp" data-wow-delay="0.3s">
										<div className="pricing-table-inner">
											<div className="pricing-table-main">
												<div className="pricing-table-header mb-24 pb-24">
													<div className="pricing-table-title h4 mt-0 mb-16 pricing-plan-name text-center">EXTRA PREMIUM</div>
													<div className="pricing-table-price text-center"><span className="pricing-table-price-currency pricing-plan-name">₹</span><span className="pricing-table-price-amount h1 pricing-plan-name">1500</span><span style={{color:"#b9c3cf"}}>/month</span></div>
												</div>
												<ul className="pricing-table-features list-reset text-xs">
													<li>
														<span>More than two location</span>
													</li>
													<li>
														<span>Link to four iPad/Tablet</span>
													</li>
													<li>
														<span>Unlimited visitors</span>
													</li>
													<li>
														<span>150 employees (hosts)</span>
													</li>
													<li>
														<span>24-hour customer care Mon-Fri</span>
													</li>
													<li>
														<span>No setup costs</span>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
								<div className="pricing-tables-wrap" style={{marginTop: '5%'}}>
									<div className="customPricingTable is-revealing wow fadeInUp" data-wow-delay="0.1s">
										<div className="pricing-table-inner">
											<div className="pricing-table-main">
											<Grid>
												<Grid.Column verticalAlign='middle' computer={7} mobile={16}>
												<div className="pricing-table-header">
													<div className="pricingtCustomText pricing-table-title h4 mt-0 pricing-plan-name">Enter your details to know more about our plans and pricing</div>
												</div>
												</Grid.Column>
												<Grid.Column only="computer" ><div className="verticalDivider">
													</div></Grid.Column>
												<Grid.Column computer={8} mobile={16}>
												<input 
												className="customInput"
												onChange={ event => {
													this.setState({
														enqName: event.target.value
													})
												}}
												value={this.state.enqName}
												type="text"
												placeholder="Name"
												name="name" />
												<input 
												className="customInput"
												onChange={ event => {
													this.setState({
														enqPhone: event.target.value
													})
												}}
												value={this.state.enqPhone}
												type="text"
												placeholder="Phone"
												name="phone" />
												<input 
												className="customInput"
												onChange={ event => {
													this.setState({
														enqEmail: event.target.value
													})
												}}
												value={this.state.enqEmail}
												type="text"
												placeholder="Email"
												name="email" />
												<div  className="pricing-table-cta">
												< Button className="button button-secondary button-shadow button-block" onClick ={this.handleEnquiry}>{this.state.buttonText}</Button>
											</div>
												</Grid.Column>
											</Grid>
											</div>
										</div>
									</div>
									</div>
							</div>
						</div>
					</div>
				</section>
				<ToastContainer autoClose={3000} />
			</div>
		);
	}
}

export default Pricing;
